if (module.hot) {
  module.hot.accept();
}

document.addEventListener("DOMContentLoaded", function() {
  // Inner link smooth scroll
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
  
        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
  });
  
  // Form submittion via Formspree
  var form = document.getElementById('contact-form-wrapper');
  async function handleSubmit(event) {
    event.preventDefault();
    var data = new FormData(event.target);
    fetch(event.target.action, {
      method: form.method,
      body: data,
      headers: {
          'Accept': 'application/json'
      }
    }).then(response => {
      document.getElementById('contact-form-wrapper').style.display = 'none';
      document.getElementById('thank-you').style.display = 'block';
      form.reset();
    }).catch(error => {
      document.getElementById('contact-form-error').style.display = 'block';
    });
  }
  form.addEventListener('submit', handleSubmit)
});

